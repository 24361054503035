import dynamic from "next/dynamic";
import { ComponentType } from "react";
import { ContentfulComponentRenderProps } from "./lib/create-contentful-component";

/*
key: Contentful content type id
value: dynamic Contentful-enabled component (has props: { entry: ContentfulEntry })
*/

export const contentfulComponents = {
  // if a site content entry is passed, render 404 page
  site: dynamic(() =>
    import("frontend/contentful/components/universal/site.contentful").then(
      (e) => ({
        default: e.ContentfulSite,
      }),
    ),
  ),

  // PAGES
  landingPage: dynamic(() =>
    import("frontend/contentful/components/pages/landing-page.contentful").then(
      (e) => ({
        default: e.ContentfulLandingPage,
      }),
    ),
  ),
  detailPage: dynamic(() =>
    import("frontend/contentful/components/pages/detail-page.contentful").then(
      (e) => ({
        default: e.ContentfulDetailPage,
      }),
    ),
  ),
  archivePage: dynamic(() =>
    import(
      "frontend/contentful/components/pages/archive-page/archive-page.contentful"
    ).then((e) => ({
      default: e.ContentfulArchivePage,
    })),
  ),
  exedProgramFinderArchivePage: dynamic(() =>
    import(
      "frontend/contentful/components/pages/archive-page/exed-program-finder.contentful"
    ).then((e) => ({
      default: e.ContentfulExEdProgramFinderArchivePage,
    })),
  ),
  articlePage: dynamic(() =>
    import("frontend/contentful/components/pages/article-page.contentful").then(
      (e) => ({
        default: e.ContentfulArticlePage,
      }),
    ),
  ),
  programPage: dynamic(() =>
    import("frontend/contentful/components/pages/program-page.contentful").then(
      (e) => ({
        default: e.ContentfulProgramPage,
      }),
    ),
  ),
  profilePage: dynamic(() =>
    import("frontend/contentful/components/pages/profile-page.contentful").then(
      (e) => ({
        default: e.ContentfulProfilePage,
      }),
    ),
  ),
  participantStory: dynamic(() =>
    import(
      "frontend/contentful/components/pages/participant-story.contentful"
    ).then((e) => ({
      default: e.ContentfulParticipantStory,
    })),
  ),
  knowledgeBaseArticlePage: dynamic(() =>
    import(
      "frontend/contentful/components/pages/knowledge-base-article-page.contentful"
    ).then((e) => ({
      default: e.ContentfulKnowledgeBaseArticlePage,
    })),
  ),
  databasePage: dynamic(() =>
    import(
      "frontend/contentful/components/pages/database-page.contentful"
    ).then((e) => ({
      default: e.ContentfulDatabasePage,
    })),
  ),
  datasetPage: dynamic(() =>
    import("frontend/contentful/components/pages/dataset-page.contentful").then(
      (e) => ({
        default: e.ContentfulDatasetPage,
      }),
    ),
  ),
  podcastEpisodePage: dynamic(() =>
    import(
      "frontend/contentful/components/pages/podcast-episode-page.contentful"
    ).then((e) => ({
      default: e.ContentfulPodcastEpisodePage,
    })),
  ),

  // SEMANTIC PAGES
  artwork: dynamic(() =>
    import("frontend/contentful/components/semantics/artwork.contentful").then(
      (e) => ({
        default: e.ContentfulArtworkPage,
      }),
    ),
  ),
  podcastEpisode: dynamic(() =>
    import(
      "frontend/contentful/components/semantics/podcast-episode.contentful"
    ).then((e) => ({
      default: e.ContentfulPodcastEpisode,
    })),
  ),

  // TOPPERS
  missionTopper: dynamic(() =>
    import(
      "frontend/contentful/components/toppers/mission-topper/mission-topper.contentful"
    ).then((e) => ({
      default: e.ContentfulMissionTopper,
    })),
  ),
  splitPageTopper: dynamic(() =>
    import(
      "frontend/contentful/components/toppers/split-page-topper.contentful"
    ).then((e) => ({
      default: e.ContentfulSplitPageTopper,
    })),
  ),
  splitPageTopperPodcast: dynamic(() =>
    import(
      "frontend/contentful/components/toppers/split-page-topper-podcast.contentful"
    ).then((e) => ({
      default: e.ContentfulSplitPageTopperPodcast,
    })),
  ),
  splitSearchTopper: dynamic(() =>
    import(
      "frontend/contentful/components/toppers/split-search-topper.contentful"
    ).then((e) => ({
      default: e.ContentfulSplitSearchTopper,
    })),
  ),
  simplePageTopper: dynamic(() =>
    import(
      "frontend/contentful/components/toppers/simple-page-topper.contentful"
    ).then((e) => ({
      default: e.ContentfulSimplePageTopper,
    })),
  ),
  statisticsTopper: dynamic(() =>
    import(
      "frontend/contentful/components/toppers/statistics-topper.contentful"
    ).then((e) => ({
      default: e.ContentfulStatisticsTopper,
    })),
  ),
  cardTopper: dynamic(() =>
    import(
      "frontend/contentful/components/toppers/card-topper.contentful"
    ).then((e) => ({
      default: e.ContentfulCardTopper,
    })),
  ),
  mediaTopper: dynamic(() =>
    import(
      "frontend/contentful/components/toppers/media-topper.contentful"
    ).then((e) => ({
      default: e.ContentfulMediaTopper,
    })),
  ),
  simpleArticleTopper: dynamic(() =>
    import(
      "frontend/contentful/components/toppers/simple-article-topper.contentful"
    ).then((e) => ({
      default: e.ContentfulSimpleArticleTopper,
    })),
  ),
  bigArtTopper: dynamic(() =>
    import(
      "frontend/contentful/components/toppers/big-art-topper.contentful"
    ).then((e) => ({
      default: e.ContentfulBigArtTopper,
    })),
  ),
  splitArticleTopper: dynamic(() =>
    import(
      "frontend/contentful/components/toppers/split-article-topper.contentful"
    ).then((e) => ({
      default: e.ContentfulSplitArticleTopper,
    })),
  ),
  editorialTopper: dynamic(() =>
    import(
      "frontend/contentful/components/toppers/editorial-topper.contentful"
    ).then((e) => ({
      default: e.ContentfulEditorialTopper,
    })),
  ),
  profileTopper: dynamic(() =>
    import(
      "frontend/contentful/components/toppers/profile-topper.contentful"
    ).then((e) => ({
      default: e.ContentfulProfileTopper,
    })),
  ),
  largeAssetTopper: dynamic(() =>
    import(
      "frontend/contentful/components/toppers/large-asset-topper.contentful"
    ).then((e) => ({
      default: e.ContentfulLargeAssetTopper,
    })),
  ),
  featuredTermTopper: dynamic(() =>
    import(
      "frontend/contentful/components/toppers/featured-term-topper.contentful"
    ).then((e) => ({
      default: e.ContentfulFeaturedTermTopper,
    })),
  ),
  selectTopper: dynamic(() =>
    import(
      "frontend/contentful/components/toppers/select-topper.contentful"
    ).then((e) => ({
      default: e.ContentfulSelectTopper,
    })),
  ),

  // BLOCKS
  accordion: dynamic(() =>
    import("frontend/contentful/components/blocks/accordion.contentful").then(
      (e) => ({
        default: e.ContentfulAccordion,
      }),
    ),
  ),
  alphanumericLinkList: dynamic(() =>
    import(
      "frontend/contentful/components/blocks/alphanumeric-link-list.contentful"
    ).then((e) => ({
      default: e.ContentfulAlphanumericLinkList,
    })),
  ),
  ctaBanner: dynamic(() =>
    import(
      "frontend/contentful/components/blocks/cta/cta-banner.contentful"
    ).then((e) => ({
      default: e.ContentfulCtaBanner,
    })),
  ),
  donationCta: dynamic(() =>
    import(
      "frontend/contentful/components/blocks/donation-cta.contentful"
    ).then((e) => ({
      default: e.ContentfulDonationCta,
    })),
  ),
  heroStatement: dynamic(() =>
    import(
      "frontend/contentful/components/blocks/hero-statement.contentful"
    ).then((e) => ({
      default: e.ContentfulHeroStatement,
    })),
  ),
  hierarchicalTease: dynamic(() =>
    import(
      "frontend/contentful/components/blocks/hierarchical-tease.contentful"
    ).then((e) => ({
      default: e.ContentfulHierarchicalTease,
    })),
  ),
  keywordScrollList: dynamic(() =>
    import(
      "frontend/contentful/components/blocks/keyword-scroll-list.contentful"
    ).then((e) => ({
      default: e.ContentfulKeywordScrollList,
    })),
  ),
  pageSectionHeader: dynamic(() =>
    import(
      "frontend/contentful/components/blocks/page-section-header.contentful"
    ).then((e) => ({
      default: e.ContentfulPageSectionHeader,
    })),
  ),
  supportingDetails: dynamic(() =>
    import(
      "frontend/contentful/components/blocks/supporting-details.contentful"
    ).then((e) => ({
      default: e.ContentfulSupportingDetails,
    })),
  ),
  smallGridList: dynamic(() =>
    import(
      "frontend/contentful/components/blocks/small-grid-list.contentful"
    ).then((e) => ({
      default: e.ContentfulSmallGridList,
    })),
  ),
  bigGridList: dynamic(() =>
    import(
      "frontend/contentful/components/blocks/big-grid-list.contentful"
    ).then((e) => ({
      default: e.ContentfulBigGridList,
    })),
  ),
  multiFeedTeaseRow: dynamic(() =>
    import(
      "frontend/contentful/components/blocks/teases/multi-feed-tease-row.contentful"
    ).then((e) => ({
      default: e.ContentfulMultiFeedTeaseRow,
    })),
  ),
  tabs: dynamic(() =>
    import("frontend/contentful/components/blocks/tabs.contentful").then(
      (e) => ({
        default: e.ContentfulTabs,
      }),
    ),
  ),
  teaseRowArticles: dynamic(() =>
    import(
      "frontend/contentful/components/blocks/teases/tease-row-articles.contentful"
    ).then((e) => ({
      default: e.ContentfulTeaseRowArticles,
    })),
  ),
  teaseRowBooks: dynamic(() =>
    import(
      "frontend/contentful/components/blocks/teases/tease-row-books.contentful"
    ).then((e) => ({
      default: e.ContentfulTeaseRowBooks,
    })),
  ),
  teaseRowContents: dynamic(() =>
    import(
      "frontend/contentful/components/blocks/teases/tease-row-contents.contentful"
    ).then((e) => ({
      default: e.ContentfulTeaseRowContents,
    })),
  ),
  teaseRowPodcasts: dynamic(() =>
    import(
      "frontend/contentful/components/blocks/teases/tease-row-podcasts.contentful"
    ).then((e) => ({
      default: e.ContentfulTeaseRowPodcasts,
    })),
  ),
  richText: dynamic(() =>
    import("frontend/contentful/components/blocks/rich-text.contentful").then(
      (e) => ({
        default: e.ContentfulRichText,
      }),
    ),
  ),
  mediaAssetRow: dynamic(() =>
    import(
      "frontend/contentful/components/blocks/media-asset-row.contentful"
    ).then((e) => ({
      default: e.ContentfulMediaAssetRow,
    })),
  ),
  sectionIntroText: dynamic(() =>
    import(
      "frontend/contentful/components/blocks/section-intro-text.contentful"
    ).then((e) => ({
      default: e.ContentfulSectionIntroText,
    })),
  ),
  timelineTease: dynamic(() =>
    import(
      "frontend/contentful/components/blocks/teases/timeline-tease.contentful"
    ).then((e) => ({
      default: e.ContentfulTimelineTease,
    })),
  ),
  heroTease: dynamic(() =>
    import(
      "frontend/contentful/components/blocks/teases/hero-tease.contentful"
    ).then((e) => ({
      default: e.ContentfulHeroTease,
    })),
  ),
  statisticsCta: dynamic(() =>
    import(
      "frontend/contentful/components/blocks/statistics-cta.contentful"
    ).then((e) => ({
      default: e.ContentfulStatisticsCta,
    })),
  ),
  statisticsGroup: dynamic(() =>
    import(
      "frontend/contentful/components/blocks/statistics-group.contentful"
    ).then((e) => ({
      default: e.ContentfulStatisticsGroup,
    })),
  ),
  table: dynamic(() =>
    import("frontend/contentful/components/blocks/table.contentful").then(
      (m) => ({ default: m.ContentfulTable }),
    ),
  ),
  teaseRowPeople: dynamic(() =>
    import(
      "frontend/contentful/components/blocks/teases/tease-row-people.contentful"
    ).then((e) => ({
      default: e.ContentfulTeaseRowPeople,
    })),
  ),
  teaseRowProgramCards: dynamic(() =>
    import(
      "frontend/contentful/components/blocks/teases/tease-row-program-cards.contentful"
    ).then((e) => ({
      default: e.ContentfulTeaseRowProgramCards,
    })),
  ),
  teaseFeedArticles: dynamic(() =>
    import(
      "frontend/contentful/components/blocks/teases/tease-feed-articles.contentful"
    ).then((e) => ({
      default: e.ContentfulTeaseFeedArticles,
    })),
  ),
  teaseFeedBooks: dynamic(() =>
    import(
      "frontend/contentful/components/blocks/teases/tease-feed-books.contentful"
    ).then((e) => ({
      default: e.ContentfulTeaseFeedBooks,
    })),
  ),
  multipleColumnList: dynamic(() =>
    import(
      "frontend/contentful/components/blocks/multiple-column-list.contentful"
    ).then((e) => ({
      default: e.ContentfulMultipleColumnList,
    })),
  ),
  podcastPlayer: dynamic(() =>
    import(
      "frontend/contentful/components/blocks/podcast-player.contentful"
    ).then((e) => ({
      default: e.ContentfulPodcastPlayer,
    })),
  ),
  pullQuote: dynamic(() =>
    import("frontend/contentful/components/blocks/pull-quote.contentful").then(
      (e) => ({
        default: e.ContentfulPullQuote,
      }),
    ),
  ),
  multiColumnRichText: dynamic(() =>
    import(
      "frontend/contentful/components/blocks/multi-column-rich-text.contentful"
    ).then((e) => ({
      default: e.ContentfulMultiColumnRichText,
    })),
  ),
  statisticsRow: dynamic(() =>
    import(
      "frontend/contentful/components/blocks/statistics-row.contentful"
    ).then((e) => ({
      default: e.ContentfulStatisticsRow,
    })),
  ),
  teaseRowParticipantStories: dynamic(() =>
    import(
      "frontend/contentful/components/blocks/teases/tease-row-participant-stories.contentful"
    ).then((e) => ({
      default: e.ContentfulTeaseRowParticipantStories,
    })),
  ),
  truncator: dynamic(() =>
    import("frontend/contentful/components/blocks/truncator.contentful").then(
      (e) => ({
        default: e.ContentfulTruncator,
      }),
    ),
  ),
  programFinder: dynamic(() =>
    import(
      "frontend/contentful/components/blocks/program-finder.contentful"
    ).then((e) => ({
      default: e.ContentfulProgramFinder,
    })),
  ),
  quoteTestimonial: dynamic(() =>
    import(
      "frontend/contentful/components/blocks/quote-testimonial.contentful"
    ).then((e) => ({
      default: e.QuoteContentfulTestimonial,
    })),
  ),
  videoTestimonial: dynamic(() =>
    import(
      "frontend/contentful/components/blocks/video-testimonial.contentful"
    ).then((e) => ({
      default: e.ContentfulVideoTestimonial,
    })),
  ),
  textCallout: dynamic(() =>
    import(
      "frontend/contentful/components/blocks/text-callout.contentful"
    ).then((e) => ({
      default: e.ContentfulTextCallout,
    })),
  ),
  sideBySideSectionIntro: dynamic(() =>
    import(
      "frontend/contentful/components/blocks/side-by-side-section-intro.contentful"
    ).then((e) => ({
      default: e.ContentfulSideBySideSectionIntro,
    })),
  ),
  sideBySideList: dynamic(() =>
    import(
      "frontend/contentful/components/blocks/side-by-side-list.contentful"
    ).then((e) => ({
      default: e.ContentfulSideBySideList,
    })),
  ),
  dataVisualizationEmbed: dynamic(() =>
    import(
      "frontend/contentful/components/blocks/data-visualization-embed.contentful"
    ).then((e) => ({
      default: e.ContentfulDataVisualizationEmbed,
    })),
  ),
  dataWrapperEmbed: dynamic(() =>
    import(
      "frontend/contentful/components/blocks/data-wrapper-embed.contentful"
    ).then((e) => ({
      default: e.ContentfulDataWrapperEmbed,
    })),
  ),
  peopleListing: dynamic(() =>
    import(
      "frontend/contentful/components/blocks/people-listing.contentful"
    ).then((e) => ({
      default: e.ContentfulPeopleListing,
    })),
  ),
  mediaCarousel: dynamic(() =>
    import(
      "frontend/contentful/components/blocks/media-carousel.contentful"
    ).then((e) => ({
      default: e.ContentfulMediaCarousel,
    })),
  ),
  hoursTable: dynamic(() =>
    import("frontend/contentful/components/blocks/hours-table.contentful").then(
      (e) => ({
        default: e.ContentfulHoursTable,
      }),
    ),
  ),
  hoursCalendar: dynamic(() =>
    import(
      "frontend/contentful/components/blocks/hours-calendar.contentful"
    ).then((e) => ({
      default: e.ContentfulHoursCalendar,
    })),
  ),
  machformEmbed: dynamic(() =>
    import(
      "frontend/contentful/components/blocks/machform-embed.contentful"
    ).then((e) => ({
      default: e.ContentfulMachFormEmbed,
    })),
  ),
  springshareFormEmbed: dynamic(() =>
    import(
      "frontend/contentful/components/blocks/springshare-embed.contentful"
    ).then((e) => ({
      default: e.ContentfulSpringShareFormEmbed,
    })),
  ),
  formAssemblyEmbed: dynamic(() =>
    import(
      "frontend/contentful/components/blocks/formassembly-embed.contentful"
    ).then((e) => ({
      default: e.ContentfulFormAssemblyEmbed,
    })),
  ),
  teaseFeedEvents: dynamic(() =>
    import(
      "frontend/contentful/components/blocks/teases/tease-feed-events.contentful"
    ).then((e) => ({
      default: e.ContentfulTeaseFeedEvents,
    })),
  ),
  multipleColumnLinkList: dynamic(() =>
    import(
      "frontend/contentful/components/blocks/multiple-column-link-list.contentful"
    ).then((e) => ({
      default: e.MultipleColumnLinkListContentful,
    })),
  ),

  // UTILS
  query: dynamic(() =>
    import("frontend/contentful/components/utils/query.contentful").then(
      (e) => ({
        default: e.ContentfulQuery,
      }),
    ),
  ),
} as const satisfies Record<
  string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ComponentType<ContentfulComponentRenderProps<any, any>>
>;
