import { Domain } from "frontend/lib/get-domain";
import { AppUrlEntry } from "../schema/app";
import { AssetRef, ContentfulIncludes } from "../schema/sys";
import { resolveAssetUrl } from "./resolve-asset.url";

type AppUrlWithAssetEntry = AppUrlEntry<
  string,
  {
    // add support for links with possible assets
    asset?: AssetRef;
  }
>;

export interface ResolveEntriesOptions {
  absolute?: boolean;
  excludeProtocol?: boolean;
  isPreview?: boolean;
  locale?: string;
}

interface ResolveEntryUrlArgs<E extends AppUrlWithAssetEntry>
  extends ResolveEntriesOptions {
  domain: Domain;
  entry?: E;
  includes: ContentfulIncludes;
  defaultLocale?: string;
  supportedLocales?: Array<string>;
}

export function resolveEntryUrl<E extends AppUrlWithAssetEntry>({
  entry,
  domain,
  includes,
  absolute,
  excludeProtocol,
  isPreview,
  locale,
  defaultLocale,
  supportedLocales,
}: ResolveEntryUrlArgs<E>): string | undefined {
  const assetUrl = resolveAssetUrl({ includes, asset: entry?.fields.asset });
  if (assetUrl) return assetUrl;

  if (!entry?.fields.url) return undefined;
  if (entry?.fields.isExternalUrl) return entry.fields.url;

  // Don't localize URL if the entry doesn't have translations
  if (!entry?.fields.hasTranslations) locale = defaultLocale;

  return resolveDomainUrl(
    domain,
    entry.fields.url,
    excludeProtocol,
    absolute,
    isPreview,
    locale,
    defaultLocale,
    supportedLocales,
  );
}

export function resolveDomainUrl(
  domain: string,
  url: string,
  excludeProtocol?: boolean,
  absolute?: boolean,
  isPreview?: boolean,
  locale?: string,
  defaultLocale?: string,
  supportedLocales?: Array<string>,
) {
  if (locale) {
    if (locale !== defaultLocale) {
      const normalizedUrl = url.replace(`${domain}.hbs.edu`, "");

      // Create a new URL object. If the URL is relative, we assume a base of 'http://dummy.com'
      const parsedUrl = new URL(normalizedUrl, "http://dummy.com");

      // Split the pathname into segments
      const pathSegments = parsedUrl.pathname
        .split("/")
        .filter((segment) => segment);

      // Add the locale as the first path segment
      pathSegments.unshift(locale);

      // Join the path segments back together and set the new pathname
      parsedUrl.pathname = pathSegments.join("/");

      // Remove the dumy base URL
      const localizedUrl = parsedUrl.toString().replace("http://dummy.com", "");

      // Add back HBS domain if it was removed
      if (url !== normalizedUrl) {
        url = `${domain}.hbs.edu${localizedUrl}`;
      } else {
        url = localizedUrl;
      }
    } else {
      supportedLocales?.forEach((supportedLocale) => {
        if (supportedLocale === defaultLocale) return;
        url = url.replace(`/${supportedLocale}`, "");
      });
    }
  }

  // Redirects support for when $1 in the destination URL
  if (url === "$1") {
    return url;
  }

  if (!absolute && url.startsWith(`${domain}.hbs.edu`)) {
    // account for the root (will be empty string)
    return url.replace(`${domain}.hbs.edu`, "") || "/";
  }

  if (url.startsWith("https://")) {
    if (excludeProtocol) return url.substring(8);
    return url;
  }

  if (excludeProtocol || url.startsWith("/")) return url;

  if (isPreview) {
    return `https://preview.${url.replace("www.", "")}`;
  }
  let updatedUrl = url;
  if (url.startsWith("library.") || url.startsWith("exed.")) {
    updatedUrl = "www." + url;
  }

  return `https://${updatedUrl}`;
}
